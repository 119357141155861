export const datos=[
    {
      "data": 0,
      "Angulo1": 0,
      "Angulo2": 0,
      "Distancia": 0
    },
    {
      "data": 1,
      "Angulo1": 0.12629518205386103,
      "Angulo2": 0.12629518205386103,
      "Distancia": 1.5075376884422111
    },
    {
      "data": 2,
      "Angulo1": 0.25259036410772207,
      "Angulo2": 0.25259036410772207,
      "Distancia": 3.0150753768844223
    },
    {
      "data": 3,
      "Angulo1": 0.3788855461615831,
      "Angulo2": 0.3788855461615831,
      "Distancia": 4.522613065326634
    },
    {
      "data": 4,
      "Angulo1": 0.5051807282154441,
      "Angulo2": 0.5051807282154441,
      "Distancia": 6.030150753768845
    },
    {
      "data": 5,
      "Angulo1": 0.6314759102693052,
      "Angulo2": 0.6314759102693052,
      "Distancia": 7.5376884422110555
    },
    {
      "data": 6,
      "Angulo1": 0.7577710923231662,
      "Angulo2": 0.7577710923231662,
      "Distancia": 9.045226130653267
    },
    {
      "data": 7,
      "Angulo1": 0.8840662743770272,
      "Angulo2": 0.8840662743770272,
      "Distancia": 10.552763819095478
    },
    {
      "data": 8,
      "Angulo1": 1.0103614564308883,
      "Angulo2": 1.0103614564308883,
      "Distancia": 12.06030150753769
    },
    {
      "data": 9,
      "Angulo1": 1.1366566384847494,
      "Angulo2": 1.1366566384847494,
      "Distancia": 13.5678391959799
    },
    {
      "data": 10,
      "Angulo1": 1.2629518205386103,
      "Angulo2": 1.2629518205386103,
      "Distancia": 15.075376884422111
    },
    {
      "data": 11,
      "Angulo1": 1.3892470025924712,
      "Angulo2": 1.3892470025924712,
      "Distancia": 16.582914572864322
    },
    {
      "data": 12,
      "Angulo1": 1.5155421846463324,
      "Angulo2": 1.5155421846463324,
      "Distancia": 18.090452261306535
    },
    {
      "data": 13,
      "Angulo1": 1.6418373667001935,
      "Angulo2": 1.6418373667001935,
      "Distancia": 19.597989949748744
    },
    {
      "data": 14,
      "Angulo1": 1.7681325487540545,
      "Angulo2": 1.7681325487540545,
      "Distancia": 21.105527638190956
    },
    {
      "data": 15,
      "Angulo1": 1.8944277308079154,
      "Angulo2": 1.8944277308079154,
      "Distancia": 22.613065326633166
    },
    {
      "data": 16,
      "Angulo1": 2.0207229128617765,
      "Angulo2": 2.0207229128617765,
      "Distancia": 24.12060301507538
    },
    {
      "data": 17,
      "Angulo1": 2.1470180949156377,
      "Angulo2": 2.1470180949156377,
      "Distancia": 25.62814070351759
    },
    {
      "data": 18,
      "Angulo1": 2.273313276969499,
      "Angulo2": 2.273313276969499,
      "Distancia": 27.1356783919598
    },
    {
      "data": 19,
      "Angulo1": 2.3996084590233595,
      "Angulo2": 2.3996084590233595,
      "Distancia": 28.643216080402013
    },
    {
      "data": 20,
      "Angulo1": 2.5259036410772207,
      "Angulo2": 2.5259036410772207,
      "Distancia": 30.150753768844222
    },
    {
      "data": 21,
      "Angulo1": 2.652198823131082,
      "Angulo2": 2.652198823131082,
      "Distancia": 31.658291457286435
    },
    {
      "data": 22,
      "Angulo1": 2.7784940051849425,
      "Angulo2": 2.7784940051849425,
      "Distancia": 33.165829145728644
    },
    {
      "data": 23,
      "Angulo1": 2.9047891872388036,
      "Angulo2": 2.9047891872388036,
      "Distancia": 34.67336683417086
    },
    {
      "data": 24,
      "Angulo1": 3.031084369292665,
      "Angulo2": 3.031084369292665,
      "Distancia": 36.18090452261307
    },
    {
      "data": 25,
      "Angulo1": 3.157379551346526,
      "Angulo2": 3.157379551346526,
      "Distancia": 37.68844221105528
    },
    {
      "data": 26,
      "Angulo1": 3.283674733400387,
      "Angulo2": 3.283674733400387,
      "Distancia": 39.19597989949749
    },
    {
      "data": 27,
      "Angulo1": 3.4099699154542478,
      "Angulo2": 3.4099699154542478,
      "Distancia": 40.7035175879397
    },
    {
      "data": 28,
      "Angulo1": 3.536265097508109,
      "Angulo2": 3.536265097508109,
      "Distancia": 42.21105527638191
    },
    {
      "data": 29,
      "Angulo1": 3.66256027956197,
      "Angulo2": 3.66256027956197,
      "Distancia": 43.718592964824126
    },
    {
      "data": 30,
      "Angulo1": 3.7888554616158308,
      "Angulo2": 3.7888554616158308,
      "Distancia": 45.22613065326633
    },
    {
      "data": 31,
      "Angulo1": 3.915150643669692,
      "Angulo2": 3.915150643669692,
      "Distancia": 46.733668341708544
    },
    {
      "data": 32,
      "Angulo1": 4.041445825723553,
      "Angulo2": 4.041445825723553,
      "Distancia": 48.24120603015076
    },
    {
      "data": 33,
      "Angulo1": 4.167741007777414,
      "Angulo2": 4.167741007777414,
      "Distancia": 49.74874371859297
    },
    {
      "data": 34,
      "Angulo1": 4.294036189831275,
      "Angulo2": 4.294036189831275,
      "Distancia": 51.25628140703518
    },
    {
      "data": 35,
      "Angulo1": 4.420331371885136,
      "Angulo2": 4.420331371885136,
      "Distancia": 52.76381909547739
    },
    {
      "data": 36,
      "Angulo1": 4.546626553938998,
      "Angulo2": 4.546626553938998,
      "Distancia": 54.2713567839196
    },
    {
      "data": 37,
      "Angulo1": 4.672921735992858,
      "Angulo2": 4.672921735992858,
      "Distancia": 55.77889447236181
    },
    {
      "data": 38,
      "Angulo1": 4.799216918046719,
      "Angulo2": 4.799216918046719,
      "Distancia": 57.286432160804026
    },
    {
      "data": 39,
      "Angulo1": 4.925512100100581,
      "Angulo2": 4.925512100100581,
      "Distancia": 58.79396984924623
    },
    {
      "data": 40,
      "Angulo1": 5.051807282154441,
      "Angulo2": 5.051807282154441,
      "Distancia": 60.301507537688444
    },
    {
      "data": 41,
      "Angulo1": 5.178102464208302,
      "Angulo2": 5.178102464208302,
      "Distancia": 61.80904522613066
    },
    {
      "data": 42,
      "Angulo1": 5.304397646262164,
      "Angulo2": 5.304397646262164,
      "Distancia": 63.31658291457287
    },
    {
      "data": 43,
      "Angulo1": 5.430692828316024,
      "Angulo2": 5.430692828316024,
      "Distancia": 64.82412060301507
    },
    {
      "data": 44,
      "Angulo1": 5.556988010369885,
      "Angulo2": 5.556988010369885,
      "Distancia": 66.33165829145729
    },
    {
      "data": 45,
      "Angulo1": 5.683283192423747,
      "Angulo2": 5.683283192423747,
      "Distancia": 67.8391959798995
    },
    {
      "data": 46,
      "Angulo1": 5.809578374477607,
      "Angulo2": 5.809578374477607,
      "Distancia": 69.34673366834171
    },
    {
      "data": 47,
      "Angulo1": 5.935873556531469,
      "Angulo2": 5.935873556531469,
      "Distancia": 70.85427135678393
    },
    {
      "data": 48,
      "Angulo1": 6.06216873858533,
      "Angulo2": 6.06216873858533,
      "Distancia": 72.36180904522614
    },
    {
      "data": 49,
      "Angulo1": 6.18846392063919,
      "Angulo2": 6.18846392063919,
      "Distancia": 73.86934673366835
    },
    {
      "data": 50,
      "Angulo1": 6.314759102693052,
      "Angulo2": 6.314759102693052,
      "Distancia": 75.37688442211056
    },
    {
      "data": 51,
      "Angulo1": 6.4410542847469126,
      "Angulo2": 6.4410542847469126,
      "Distancia": 76.88442211055276
    },
    {
      "data": 52,
      "Angulo1": 6.567349466800774,
      "Angulo2": 6.567349466800774,
      "Distancia": 78.39195979899498
    },
    {
      "data": 53,
      "Angulo1": 6.693644648854635,
      "Angulo2": 6.693644648854635,
      "Distancia": 79.89949748743719
    },
    {
      "data": 54,
      "Angulo1": 6.8199398309084955,
      "Angulo2": 6.8199398309084955,
      "Distancia": 81.4070351758794
    },
    {
      "data": 55,
      "Angulo1": 6.946235012962357,
      "Angulo2": 6.946235012962357,
      "Distancia": 82.91457286432161
    },
    {
      "data": 56,
      "Angulo1": 7.072530195016218,
      "Angulo2": 7.072530195016218,
      "Distancia": 84.42211055276383
    },
    {
      "data": 57,
      "Angulo1": 7.1988253770700785,
      "Angulo2": 7.1988253770700785,
      "Distancia": 85.92964824120604
    },
    {
      "data": 58,
      "Angulo1": 7.32512055912394,
      "Angulo2": 7.32512055912394,
      "Distancia": 87.43718592964825
    },
    {
      "data": 59,
      "Angulo1": 7.451415741177801,
      "Angulo2": 7.451415741177801,
      "Distancia": 88.94472361809046
    },
    {
      "data": 60,
      "Angulo1": 7.5777109232316615,
      "Angulo2": 7.5777109232316615,
      "Distancia": 90.45226130653266
    },
    {
      "data": 61,
      "Angulo1": 7.704006105285523,
      "Angulo2": 7.704006105285523,
      "Distancia": 91.95979899497488
    },
    {
      "data": 62,
      "Angulo1": 7.830301287339384,
      "Angulo2": 7.830301287339384,
      "Distancia": 93.46733668341709
    },
    {
      "data": 63,
      "Angulo1": 7.956596469393245,
      "Angulo2": 7.956596469393245,
      "Distancia": 94.9748743718593
    },
    {
      "data": 64,
      "Angulo1": 8.082891651447106,
      "Angulo2": 8.082891651447106,
      "Distancia": 96.48241206030151
    },
    {
      "data": 65,
      "Angulo1": 8.209186833500967,
      "Angulo2": 8.209186833500967,
      "Distancia": 97.98994974874373
    },
    {
      "data": 66,
      "Angulo1": 8.335482015554827,
      "Angulo2": 8.335482015554827,
      "Distancia": 99.49748743718594
    },
    {
      "data": 67,
      "Angulo1": 8.46177719760869,
      "Angulo2": 8.46177719760869,
      "Distancia": 101.00502512562815
    },
    {
      "data": 68,
      "Angulo1": 8.58807237966255,
      "Angulo2": 8.58807237966255,
      "Distancia": 102.51256281407036
    },
    {
      "data": 69,
      "Angulo1": 8.714367561716411,
      "Angulo2": 8.714367561716411,
      "Distancia": 104.02010050251256
    },
    {
      "data": 70,
      "Angulo1": 8.840662743770272,
      "Angulo2": 8.840662743770272,
      "Distancia": 105.52763819095478
    },
    {
      "data": 71,
      "Angulo1": 8.966957925824133,
      "Angulo2": 8.966957925824133,
      "Distancia": 107.03517587939699
    },
    {
      "data": 72,
      "Angulo1": 9.093253107877995,
      "Angulo2": 9.093253107877995,
      "Distancia": 108.5427135678392
    },
    {
      "data": 73,
      "Angulo1": 9.219548289931856,
      "Angulo2": 9.219548289931856,
      "Distancia": 110.05025125628141
    },
    {
      "data": 74,
      "Angulo1": 9.345843471985717,
      "Angulo2": 9.345843471985717,
      "Distancia": 111.55778894472363
    },
    {
      "data": 75,
      "Angulo1": 9.472138654039577,
      "Angulo2": 9.472138654039577,
      "Distancia": 113.06532663316584
    },
    {
      "data": 76,
      "Angulo1": 9.598433836093438,
      "Angulo2": 9.598433836093438,
      "Distancia": 114.57286432160805
    },
    {
      "data": 77,
      "Angulo1": 9.724729018147299,
      "Angulo2": 9.724729018147299,
      "Distancia": 116.08040201005026
    },
    {
      "data": 78,
      "Angulo1": 9.851024200201161,
      "Angulo2": 9.851024200201161,
      "Distancia": 117.58793969849246
    },
    {
      "data": 79,
      "Angulo1": 9.977319382255022,
      "Angulo2": 9.977319382255022,
      "Distancia": 119.09547738693468
    },
    {
      "data": 80,
      "Angulo1": 10.103614564308883,
      "Angulo2": 10.103614564308883,
      "Distancia": 120.60301507537689
    },
    {
      "data": 81,
      "Angulo1": 10.229909746362743,
      "Angulo2": 10.229909746362743,
      "Distancia": 122.1105527638191
    },
    {
      "data": 82,
      "Angulo1": 10.356204928416604,
      "Angulo2": 10.356204928416604,
      "Distancia": 123.61809045226131
    },
    {
      "data": 83,
      "Angulo1": 10.482500110470466,
      "Angulo2": 10.482500110470466,
      "Distancia": 125.12562814070353
    },
    {
      "data": 84,
      "Angulo1": 10.608795292524327,
      "Angulo2": 10.608795292524327,
      "Distancia": 126.63316582914574
    },
    {
      "data": 85,
      "Angulo1": 10.735090474578188,
      "Angulo2": 10.735090474578188,
      "Distancia": 128.14070351758795
    },
    {
      "data": 86,
      "Angulo1": 10.861385656632049,
      "Angulo2": 10.861385656632049,
      "Distancia": 129.64824120603015
    },
    {
      "data": 87,
      "Angulo1": 10.98768083868591,
      "Angulo2": 10.98768083868591,
      "Distancia": 131.15577889447238
    },
    {
      "data": 88,
      "Angulo1": 11.11397602073977,
      "Angulo2": 11.11397602073977,
      "Distancia": 132.66331658291458
    },
    {
      "data": 89,
      "Angulo1": 11.240271202793632,
      "Angulo2": 11.240271202793632,
      "Distancia": 134.1708542713568
    },
    {
      "data": 90,
      "Angulo1": 11.366566384847493,
      "Angulo2": 11.366566384847493,
      "Distancia": 135.678391959799
    },
    {
      "data": 91,
      "Angulo1": 11.492861566901354,
      "Angulo2": 11.492861566901354,
      "Distancia": 137.18592964824123
    },
    {
      "data": 92,
      "Angulo1": 11.619156748955215,
      "Angulo2": 11.619156748955215,
      "Distancia": 138.69346733668343
    },
    {
      "data": 93,
      "Angulo1": 11.745451931009075,
      "Angulo2": 11.745451931009075,
      "Distancia": 140.20100502512562
    },
    {
      "data": 94,
      "Angulo1": 11.871747113062938,
      "Angulo2": 11.871747113062938,
      "Distancia": 141.70854271356785
    },
    {
      "data": 95,
      "Angulo1": 11.998042295116798,
      "Angulo2": 11.998042295116798,
      "Distancia": 143.21608040201005
    },
    {
      "data": 96,
      "Angulo1": 12.12433747717066,
      "Angulo2": 12.12433747717066,
      "Distancia": 144.72361809045228
    },
    {
      "data": 97,
      "Angulo1": 12.25063265922452,
      "Angulo2": 12.25063265922452,
      "Distancia": 146.23115577889448
    },
    {
      "data": 98,
      "Angulo1": 12.37692784127838,
      "Angulo2": 12.37692784127838,
      "Distancia": 147.7386934673367
    },
    {
      "data": 99,
      "Angulo1": 12.503223023332243,
      "Angulo2": 12.503223023332243,
      "Distancia": 149.2462311557789
    },
    {
      "data": 100,
      "Angulo1": 12.629518205386104,
      "Angulo2": 12.629518205386104,
      "Distancia": 150.75376884422113
    },
    {
      "data": 101,
      "Angulo1": 12.755813387439964,
      "Angulo2": 12.755813387439964,
      "Distancia": 152.26130653266333
    },
    {
      "data": 102,
      "Angulo1": 12.882108569493825,
      "Angulo2": 12.882108569493825,
      "Distancia": 153.76884422110552
    },
    {
      "data": 103,
      "Angulo1": 13.008403751547686,
      "Angulo2": 13.008403751547686,
      "Distancia": 155.27638190954775
    },
    {
      "data": 104,
      "Angulo1": 13.134698933601548,
      "Angulo2": 13.134698933601548,
      "Distancia": 156.78391959798995
    },
    {
      "data": 105,
      "Angulo1": 13.260994115655409,
      "Angulo2": 13.260994115655409,
      "Distancia": 158.29145728643218
    },
    {
      "data": 106,
      "Angulo1": 13.38728929770927,
      "Angulo2": 13.38728929770927,
      "Distancia": 159.79899497487438
    },
    {
      "data": 107,
      "Angulo1": 13.51358447976313,
      "Angulo2": 13.51358447976313,
      "Distancia": 161.3065326633166
    },
    {
      "data": 108,
      "Angulo1": 13.639879661816991,
      "Angulo2": 13.639879661816991,
      "Distancia": 162.8140703517588
    },
    {
      "data": 109,
      "Angulo1": 13.766174843870852,
      "Angulo2": 13.766174843870852,
      "Distancia": 164.32160804020103
    },
    {
      "data": 110,
      "Angulo1": 13.892470025924714,
      "Angulo2": 13.892470025924714,
      "Distancia": 165.82914572864323
    },
    {
      "data": 111,
      "Angulo1": 14.018765207978575,
      "Angulo2": 14.018765207978575,
      "Distancia": 167.33668341708542
    },
    {
      "data": 112,
      "Angulo1": 14.145060390032436,
      "Angulo2": 14.145060390032436,
      "Distancia": 168.84422110552765
    },
    {
      "data": 113,
      "Angulo1": 14.271355572086296,
      "Angulo2": 14.271355572086296,
      "Distancia": 170.35175879396985
    },
    {
      "data": 114,
      "Angulo1": 14.397650754140157,
      "Angulo2": 14.397650754140157,
      "Distancia": 171.85929648241208
    },
    {
      "data": 115,
      "Angulo1": 14.52394593619402,
      "Angulo2": 14.52394593619402,
      "Distancia": 173.36683417085428
    },
    {
      "data": 116,
      "Angulo1": 14.65024111824788,
      "Angulo2": 14.65024111824788,
      "Distancia": 174.8743718592965
    },
    {
      "data": 117,
      "Angulo1": 14.776536300301741,
      "Angulo2": 14.776536300301741,
      "Distancia": 176.3819095477387
    },
    {
      "data": 118,
      "Angulo1": 14.902831482355602,
      "Angulo2": 14.902831482355602,
      "Distancia": 177.88944723618093
    },
    {
      "data": 119,
      "Angulo1": 15.029126664409462,
      "Angulo2": 15.029126664409462,
      "Distancia": 179.39698492462313
    },
    {
      "data": 120,
      "Angulo1": 15.155421846463323,
      "Angulo2": 15.155421846463323,
      "Distancia": 180.90452261306532
    },
    {
      "data": 121,
      "Angulo1": 15.281717028517185,
      "Angulo2": 15.281717028517185,
      "Distancia": 182.41206030150755
    },
    {
      "data": 122,
      "Angulo1": 15.408012210571046,
      "Angulo2": 15.408012210571046,
      "Distancia": 183.91959798994975
    },
    {
      "data": 123,
      "Angulo1": 15.534307392624907,
      "Angulo2": 15.534307392624907,
      "Distancia": 185.42713567839198
    },
    {
      "data": 124,
      "Angulo1": 15.660602574678768,
      "Angulo2": 15.660602574678768,
      "Distancia": 186.93467336683418
    },
    {
      "data": 125,
      "Angulo1": 15.786897756732628,
      "Angulo2": 15.786897756732628,
      "Distancia": 188.4422110552764
    },
    {
      "data": 126,
      "Angulo1": 15.91319293878649,
      "Angulo2": 15.91319293878649,
      "Distancia": 189.9497487437186
    },
    {
      "data": 127,
      "Angulo1": 16.03948812084035,
      "Angulo2": 16.03948812084035,
      "Distancia": 191.45728643216083
    },
    {
      "data": 128,
      "Angulo1": 16.165783302894212,
      "Angulo2": 16.165783302894212,
      "Distancia": 192.96482412060303
    },
    {
      "data": 129,
      "Angulo1": 16.292078484948075,
      "Angulo2": 16.292078484948075,
      "Distancia": 194.47236180904522
    },
    {
      "data": 130,
      "Angulo1": 16.418373667001934,
      "Angulo2": 16.418373667001934,
      "Distancia": 195.97989949748745
    },
    {
      "data": 131,
      "Angulo1": 16.544668849055796,
      "Angulo2": 16.544668849055796,
      "Distancia": 197.48743718592965
    },
    {
      "data": 132,
      "Angulo1": 16.670964031109655,
      "Angulo2": 16.670964031109655,
      "Distancia": 198.99497487437188
    },
    {
      "data": 133,
      "Angulo1": 16.797259213163517,
      "Angulo2": 16.797259213163517,
      "Distancia": 200.50251256281408
    },
    {
      "data": 134,
      "Angulo1": 16.92355439521738,
      "Angulo2": 16.92355439521738,
      "Distancia": 202.0100502512563
    },
    {
      "data": 135,
      "Angulo1": 17.04984957727124,
      "Angulo2": 17.04984957727124,
      "Distancia": 203.5175879396985
    },
    {
      "data": 136,
      "Angulo1": 17.1761447593251,
      "Angulo2": 17.1761447593251,
      "Distancia": 205.02512562814073
    },
    {
      "data": 137,
      "Angulo1": 17.30243994137896,
      "Angulo2": 17.30243994137896,
      "Distancia": 206.53266331658293
    },
    {
      "data": 138,
      "Angulo1": 17.428735123432823,
      "Angulo2": 17.428735123432823,
      "Distancia": 208.04020100502512
    },
    {
      "data": 139,
      "Angulo1": 17.555030305486685,
      "Angulo2": 17.555030305486685,
      "Distancia": 209.54773869346735
    },
    {
      "data": 140,
      "Angulo1": 17.681325487540544,
      "Angulo2": 17.681325487540544,
      "Distancia": 211.05527638190955
    },
    {
      "data": 141,
      "Angulo1": 17.807620669594407,
      "Angulo2": 17.807620669594407,
      "Distancia": 212.56281407035178
    },
    {
      "data": 142,
      "Angulo1": 17.933915851648266,
      "Angulo2": 17.933915851648266,
      "Distancia": 214.07035175879398
    },
    {
      "data": 143,
      "Angulo1": 18.060211033702128,
      "Angulo2": 18.060211033702128,
      "Distancia": 215.5778894472362
    },
    {
      "data": 144,
      "Angulo1": 18.18650621575599,
      "Angulo2": 18.18650621575599,
      "Distancia": 217.0854271356784
    },
    {
      "data": 145,
      "Angulo1": 18.31280139780985,
      "Angulo2": 18.31280139780985,
      "Distancia": 218.59296482412063
    },
    {
      "data": 146,
      "Angulo1": 18.439096579863712,
      "Angulo2": 18.439096579863712,
      "Distancia": 220.10050251256283
    },
    {
      "data": 147,
      "Angulo1": 18.56539176191757,
      "Angulo2": 18.56539176191757,
      "Distancia": 221.60804020100502
    },
    {
      "data": 148,
      "Angulo1": 18.691686943971433,
      "Angulo2": 18.691686943971433,
      "Distancia": 223.11557788944725
    },
    {
      "data": 149,
      "Angulo1": 18.817982126025292,
      "Angulo2": 18.817982126025292,
      "Distancia": 224.62311557788945
    },
    {
      "data": 150,
      "Angulo1": 18.944277308079155,
      "Angulo2": 18.944277308079155,
      "Distancia": 226.13065326633168
    },
    {
      "data": 151,
      "Angulo1": 19.070572490133017,
      "Angulo2": 19.070572490133017,
      "Distancia": 227.63819095477388
    },
    {
      "data": 152,
      "Angulo1": 19.196867672186876,
      "Angulo2": 19.196867672186876,
      "Distancia": 229.1457286432161
    },
    {
      "data": 153,
      "Angulo1": 19.32316285424074,
      "Angulo2": 19.32316285424074,
      "Distancia": 230.6532663316583
    },
    {
      "data": 154,
      "Angulo1": 19.449458036294597,
      "Angulo2": 19.449458036294597,
      "Distancia": 232.16080402010053
    },
    {
      "data": 155,
      "Angulo1": 19.57575321834846,
      "Angulo2": 19.57575321834846,
      "Distancia": 233.66834170854273
    },
    {
      "data": 156,
      "Angulo1": 19.702048400402322,
      "Angulo2": 19.702048400402322,
      "Distancia": 235.17587939698493
    },
    {
      "data": 157,
      "Angulo1": 19.82834358245618,
      "Angulo2": 19.82834358245618,
      "Distancia": 236.68341708542715
    },
    {
      "data": 158,
      "Angulo1": 19.954638764510044,
      "Angulo2": 19.954638764510044,
      "Distancia": 238.19095477386935
    },
    {
      "data": 159,
      "Angulo1": 20.080933946563903,
      "Angulo2": 20.080933946563903,
      "Distancia": 239.69849246231158
    },
    {
      "data": 160,
      "Angulo1": 20.207229128617765,
      "Angulo2": 20.207229128617765,
      "Distancia": 241.20603015075378
    },
    {
      "data": 161,
      "Angulo1": 20.333524310671628,
      "Angulo2": 20.333524310671628,
      "Distancia": 242.713567839196
    },
    {
      "data": 162,
      "Angulo1": 20.459819492725487,
      "Angulo2": 20.459819492725487,
      "Distancia": 244.2211055276382
    },
    {
      "data": 163,
      "Angulo1": 20.58611467477935,
      "Angulo2": 20.58611467477935,
      "Distancia": 245.72864321608043
    },
    {
      "data": 164,
      "Angulo1": 20.712409856833208,
      "Angulo2": 20.712409856833208,
      "Distancia": 247.23618090452263
    },
    {
      "data": 165,
      "Angulo1": 20.83870503888707,
      "Angulo2": 20.83870503888707,
      "Distancia": 248.74371859296483
    },
    {
      "data": 166,
      "Angulo1": 20.965000220940933,
      "Angulo2": 20.965000220940933,
      "Distancia": 250.25125628140705
    },
    {
      "data": 167,
      "Angulo1": 21.091295402994792,
      "Angulo2": 21.091295402994792,
      "Distancia": 251.75879396984925
    },
    {
      "data": 168,
      "Angulo1": 21.217590585048654,
      "Angulo2": 21.217590585048654,
      "Distancia": 253.26633165829148
    },
    {
      "data": 169,
      "Angulo1": 21.343885767102513,
      "Angulo2": 21.343885767102513,
      "Distancia": 254.77386934673368
    },
    {
      "data": 170,
      "Angulo1": 21.470180949156376,
      "Angulo2": 21.470180949156376,
      "Distancia": 256.2814070351759
    },
    {
      "data": 171,
      "Angulo1": 21.59647613121024,
      "Angulo2": 21.59647613121024,
      "Distancia": 257.7889447236181
    },
    {
      "data": 172,
      "Angulo1": 21.722771313264097,
      "Angulo2": 21.722771313264097,
      "Distancia": 259.2964824120603
    },
    {
      "data": 173,
      "Angulo1": 21.84906649531796,
      "Angulo2": 21.84906649531796,
      "Distancia": 260.80402010050256
    },
    {
      "data": 174,
      "Angulo1": 21.97536167737182,
      "Angulo2": 21.97536167737182,
      "Distancia": 262.31155778894475
    },
    {
      "data": 175,
      "Angulo1": 22.10165685942568,
      "Angulo2": 22.10165685942568,
      "Distancia": 263.81909547738695
    },
    {
      "data": 176,
      "Angulo1": 22.22795204147954,
      "Angulo2": 22.22795204147954,
      "Distancia": 265.32663316582915
    },
    {
      "data": 177,
      "Angulo1": 22.354247223533402,
      "Angulo2": 22.354247223533402,
      "Distancia": 266.83417085427135
    },
    {
      "data": 178,
      "Angulo1": 22.480542405587265,
      "Angulo2": 22.480542405587265,
      "Distancia": 268.3417085427136
    },
    {
      "data": 179,
      "Angulo1": 22.606837587641124,
      "Angulo2": 22.606837587641124,
      "Distancia": 269.8492462311558
    },
    {
      "data": 180,
      "Angulo1": 22.733132769694986,
      "Angulo2": 22.733132769694986,
      "Distancia": 271.356783919598
    },
    {
      "data": 181,
      "Angulo1": 22.859427951748845,
      "Angulo2": 22.859427951748845,
      "Distancia": 272.8643216080402
    },
    {
      "data": 182,
      "Angulo1": 22.985723133802708,
      "Angulo2": 22.985723133802708,
      "Distancia": 274.37185929648246
    },
    {
      "data": 183,
      "Angulo1": 23.11201831585657,
      "Angulo2": 23.11201831585657,
      "Distancia": 275.87939698492465
    },
    {
      "data": 184,
      "Angulo1": 23.23831349791043,
      "Angulo2": 23.23831349791043,
      "Distancia": 277.38693467336685
    },
    {
      "data": 185,
      "Angulo1": 23.36460867996429,
      "Angulo2": 23.36460867996429,
      "Distancia": 278.89447236180905
    },
    {
      "data": 186,
      "Angulo1": 23.49090386201815,
      "Angulo2": 23.49090386201815,
      "Distancia": 280.40201005025125
    },
    {
      "data": 187,
      "Angulo1": 23.617199044072013,
      "Angulo2": 23.617199044072013,
      "Distancia": 281.9095477386935
    },
    {
      "data": 188,
      "Angulo1": 23.743494226125875,
      "Angulo2": 23.743494226125875,
      "Distancia": 283.4170854271357
    },
    {
      "data": 189,
      "Angulo1": 23.869789408179734,
      "Angulo2": 23.869789408179734,
      "Distancia": 284.9246231155779
    },
    {
      "data": 190,
      "Angulo1": 23.996084590233597,
      "Angulo2": 23.996084590233597,
      "Distancia": 286.4321608040201
    },
    {
      "data": 191,
      "Angulo1": 24.122379772287456,
      "Angulo2": 24.122379772287456,
      "Distancia": 287.93969849246236
    },
    {
      "data": 192,
      "Angulo1": 24.24867495434132,
      "Angulo2": 24.24867495434132,
      "Distancia": 289.44723618090455
    },
    {
      "data": 193,
      "Angulo1": 24.37497013639518,
      "Angulo2": 24.37497013639518,
      "Distancia": 290.95477386934675
    },
    {
      "data": 194,
      "Angulo1": 24.50126531844904,
      "Angulo2": 24.50126531844904,
      "Distancia": 292.46231155778895
    },
    {
      "data": 195,
      "Angulo1": 24.627560500502902,
      "Angulo2": 24.627560500502902,
      "Distancia": 293.96984924623115
    },
    {
      "data": 196,
      "Angulo1": 24.75385568255676,
      "Angulo2": 24.75385568255676,
      "Distancia": 295.4773869346734
    },
    {
      "data": 197,
      "Angulo1": 24.880150864610624,
      "Angulo2": 24.880150864610624,
      "Distancia": 296.9849246231156
    },
    {
      "data": 198,
      "Angulo1": 25.006446046664486,
      "Angulo2": 25.006446046664486,
      "Distancia": 298.4924623115578
    },
    {
      "data": 199,
      "Angulo1": 25.132741228718345,
      "Angulo2": 25.132741228718345,
      "Distancia": 300
    }
  ]